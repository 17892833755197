.no-rounded {
  border-radius: 0 !important;
}

.ck-editor__editable[role="textbox"] {
  /* editing area */
  min-height: 150px;
}

// custom sweetalert
.swal2-popup, .swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel{
  border-radius: 0 !important;
}

// custom box homepage
.text-gray-nf{
  color: rgb(131, 137, 148);
}
span.info-box-text-nf{
  font-size:0.857143rem !important;
  color: rgb(131, 137, 148);
}
span.info-box-number-nf{
  display: block;
  position: relative;
  font-weight: 800;
  font-family: "Nunito Sans", -apple-system, sans-serif;
  font-size: 1.71429rem;
  line-height: 30px;
  color: rgba(49, 53, 59, 0.96);
  text-decoration: initial;
  margin: 4px 0px;
}
.product-img-cart {
  width: 40px !important;
  height: 40px !important;
}